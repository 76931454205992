import React, { useEffect, useState, Suspense, lazy } from "react"
import { connect } from "react-redux"
import { Routes, Route, Navigate } from 'react-router-dom'

import { ConfigProvider } from 'antd'
import { Wrapper, PageContent, PageWrapper } from "./style"

import Header from "../../common/header"

import enUS from 'antd/es/locale/en_US'
import zhCN from 'antd/es/locale/zh_CN'
import jaJP from 'antd/es/locale/ja_JP'

const Menu = lazy(() => import(/* webpackChunkName: 'menu' */'../../common/menu'))
const Footer = lazy(() => import(/* webpackChunkName: 'footer' */'../../common/footer'))

const Home = lazy(() => import(/* webpackChunkName: 'home' */'../../pages/Home'))
const DeviceAnalyse = lazy(() => import(/* webpackChunkName: 'deviceAnalyse' */'../../pages/deviceAnalyse'))
const DeviceReport = lazy(() => import(/* webpackChunkName: 'deviceReport' */'../../pages/deviceReport'))
const PsInfo = lazy(() => import(/* webpackChunkName: 'PsInfo' */'../../pages/PsInfo'))
const Alarm = lazy(() => import(/* webpackChunkName: 'Alarm' */'../../pages/alarm'))
const Report = lazy(() => import(/* webpackChunkName: 'Report' */'../../pages/Report'))
const PsInfoCompare = lazy(() => import(/* webpackChunkName: 'psInfoCompare' */'../../pages/psInfoCompare'))
const DeviceCompare = lazy(() => import(/* webpackChunkName: 'deviceCompare' */'../../pages/deviceCompare'))
const Helper = lazy(() => import(/* webpackChunkName: 'deviceCompare' */'../../pages/Helper'))
const PsTrans = lazy(() => import(/* webpackChunkName: 'PsInfo' */'../../pages/PsTrans'))
const Command = lazy(() => import(/* webpackChunkName: 'PsInfo' */'../../pages/Command'))
const DeviceManagement = lazy(() => import(/* webpackChunkName: 'deviceManagement' */'../../pages/deviceManagement'))

const Content = (props) => {
    const [locale, setLocal] = useState(null);
    //切换语言
    useEffect(() => {
        switch (props.language) {
            case "zh-cn":
                setLocal(zhCN)
                break;
            case "ja-jp":
                setLocal(jaJP)
                break;
            case "en-us":
                setLocal(enUS)
                break;
            default: setLocal(zhCN); break;
        }
    }, [props.language])

    return (
        <>
            <Header />
            <Menu />
            <ConfigProvider locale={locale}>
                <Wrapper >
                    <PageWrapper className={`page-wrapper ${props.collapsed ? 'toggled' : ''}`}  >
                        <PageContent>
                            <Suspense fallback={<div>Loading...</div>}>
                                {/*<Routes>
                                    <Route path='/' element={<Home/>} />
                                    <Route path="/page/home" element={<Home />}></Route>
                                    <Route path="about" element={<About />}></Route>
                                    <Route path="/deviceReport" element={<deviceReport />}></Route>
                                </Routes>*/}
                                {/* 注册路由 */}
                                <Routes>
                                    <Route path="/home" element={<Home />} />
                                    <Route path="/psinfo" element={<PsInfo />} />
                                    <Route path="/report" element={<Report />} />
                                    <Route path="/helper" element={<Helper />} />

                                    <Route path="/psInfoCompare" element={<PsInfoCompare />}></Route>
                                    <Route path="/deviceReport" element={<DeviceReport />}></Route>
                                    <Route path="/deviceAnalyse" element={<DeviceAnalyse />}></Route>
                                    <Route path="/alarm" element={<Alarm />}></Route>
                                    <Route path="/deviceCompare" element={<DeviceCompare />}></Route>

                                    <Route path="/psTrans" element={<PsTrans />}></Route>
                                    <Route path="/command" element={<Command />}></Route>
                                    <Route path="/deviceManagement" element={<DeviceManagement />}></Route>
                                    <Route path="*" element={<Navigate to="404" />} />
                                </Routes>
                            </Suspense>

                        </PageContent>
                    </PageWrapper>
                </Wrapper>

                <Footer />
            </ConfigProvider>
        </>
    )
}
const mapState = (state) => {
    return {
        collapsed: state.getIn(['menu', 'collapsed']),
        language: state.getIn(['header', 'global', 'language']),
    }
}

export default connect(mapState, null)(Content) 